import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi } from "../../../components/src/Utilities";
import { createRef } from "react";




type DebouncedFunction = (...args: any[]) => void;

interface ISocialMediaLink {
  facebook_link: string;
  twitter_link: string;
  linkedin_link: string;
}


interface IFormData {
    email?: string;
    profileHandle?: string;
    location?: string;
    bio?: string;
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    full_name?: string | null;
}

interface IProfileValidation {
  flag: boolean;
  message: string;
}

export interface IAccountAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  name: string;
  first_name: string | null;
  full_phone_number: string;
  last_name: string | null;
  phone_number: string | null;
  type: string;
  profile_handle: string;
  user_location: string;
  bio: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  profile_image: string | null;
  social_media_link: ISocialMediaLink;
  post_count: number;
  followers: number;
  following: number;
  full_name: string | null
}

export interface IAccountData {
  id: string;
  type: string;
  attributes: IAccountAttributes;
}

interface IAccountResponse {
  data: IAccountData;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  profileData: IAccountData | undefined;
  isProfileValidationLoading: boolean;
  isValidationError: boolean;
  profileValidationMsg: string | undefined;
  openEditImgModal: boolean;
  isWebCamOpen: boolean;
  selectedFile: File[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef = createRef<HTMLInputElement>();
  // Customizable Area Ends

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      profileData: undefined,
      isProfileValidationLoading: false,
      isValidationError: false,
      profileValidationMsg: undefined,
      openEditImgModal: false,
      isWebCamOpen: false,
      selectedFile: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserProfileDetails();
  }

  getProfileId: string = "";
  validateHandleId: string = "";
  updateProfileDetailsId: string = "";
  editProfileImg: string = "";


  apiFailureCallBacks = () => {
    toast.error(configJSON.fetchErrorMsg);
    this.setState({ isLoading: false, isProfileValidationLoading: false });
  }

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: IAccountResponse & IProfileValidation) => {

    switch (apiRequestCallId) {
      case this.getProfileId:
        this.handleProfileSuccessCallback(responseJson)
        break;

      case this.validateHandleId:
        this.handleValidationSuccessCallback(responseJson)
        break;

      case this.updateProfileDetailsId:
        this.handleUpdateProfileCallback(responseJson);
        break;

      case this.editProfileImg: 
        this.getUserProfileDetails();
        break;

    }
  }

  handleValidationSuccessCallback = (responseJson: IProfileValidation) => {
    this.setState({
      isProfileValidationLoading: false,
      profileValidationMsg: responseJson?.message,
      isValidationError: !responseJson?.flag
    })
  }


  handleUpdateProfileCallback = (responseJson: IAccountResponse) => {
    if (responseJson?.data){
      toast.success(configJSON.profileUpdated);
      this.navigateToPage("UserProfileBasicBlock");
    }
  }


  navigateToPage = (path: string) => {
    const navigateToHomePageMsg = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateToHomePageMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      path
    );
    navigateToHomePageMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToHomePageMsg);
  };


  toggleEditProfileImg = (toggle: boolean) => {
    this.setState({
      openEditImgModal: toggle
    })
  }

  base64ToFile = (base64String: string, fileName: string, mimeType: string): File => {
    const byteString = atob(base64String.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
  
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    return new File([ab], fileName, { type: mimeType });
  };


  processCameraImage = (baseString: string | null | undefined) => {
    this.setState({
      isWebCamOpen: false
    })
    let file;
    if (baseString){
      file = this.base64ToFile(baseString, "profile.png", "image/png");
    }
    else{
      file = new File([], "")
    }
    this.updateProfileImg(file);
  }


  updateProfileImg = async (fileObj: File) => {

      const authToken = await getStorageData("token");
      const file = fileObj
      const formData = new FormData();
      const header = { token: authToken };
      const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.editProfileImg = reqMessage.messageId;
  
      formData.append('profile_image', file);
  
      reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);
      reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.editprofileImgEndpoint);
      reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      runEngine.sendMessage(reqMessage.id, reqMessage);

  }


  toggleWebCam = (toggle: boolean) => {
    this.setState({
      isWebCamOpen: toggle
    })
  }

  
  handleProfileSuccessCallback = (responseJson: IAccountResponse) => {
    this.setState({
      profileData: responseJson?.data,
      isLoading: false
    })
  }
  

  debounce = <T extends DebouncedFunction>(func: (event: React.ChangeEvent<HTMLInputElement>) => void, delay: number): T => {
    let timer: NodeJS.Timeout | undefined;
    return ((args: React.ChangeEvent<HTMLInputElement>) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => func(args), delay);
    }) as T;
  };


  handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const handleName = event.target.value;
    this.setState({
      isProfileValidationLoading: true
    })
    const authToken = await getStorageData("token")

    this.validateHandleId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${(configJSON.validateProfileEndpoint as string)}?profile_handle=${handleName}`,
      headers: { "token": authToken},
    }, runEngine);

  }

  validateProfileHandle = this.debounce(this.handleInputChange, configJSON.debounceTime);

  getUserProfileDetails = async () => {

    this.setState({ isLoading: true });
    const authToken = await getStorageData("token");

    this.getProfileId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${(configJSON.getUserDetailsEndpoint as string)}`,
      headers: { "token": authToken},
    }, runEngine);

  }

  updateUserProfile = async (values: IFormData) => {
    
    this.setState({ isLoading: true })
    const authToken = await getStorageData("token");

    const payload = {
      data: {
        attributes: {
          profile_handle: values?.profileHandle,
          user_location: values?.location,
          bio: values?.bio,
          facebook_link: values?.facebook,
          twitter_link: values?.twitter,
          linkedin_link: values?.linkedin,
          full_name: values?.full_name,
        },
      },
    };

    this.updateProfileDetailsId = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.httpPutMethod,
      endPoint: `${(configJSON.editProfileEndpoint as string)}`,
      body: payload,
      headers: { "token": authToken},
    }, runEngine);
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      this.setState({
        selectedFile: [...fileArray]
      })
      this.updateProfileImg(files[0])
    }

  };


  handleFileUploadClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (responseJson?.errors  || errorResponse || responseJson?.error) {
      this.apiFailureCallBacks();
    }

    else if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBacks(apiRequestCallId, responseJson);
    }
    
    // Customizable Area End
  }
}
