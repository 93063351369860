import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  styled,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton";

interface Props {
  isOpen: boolean;
  processImg: (imageSrc: string | null | undefined) => void;
  handleClose: (toggle: boolean) => void;
  openCamModal: (toggle: boolean) => void;
  handleFileUploadClick: () => void;
}

const Styleddialog = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    position: "absolute",
    bottom: 0,
  },
});


const CustomEditImageModal = (props: Props) => {
  const { isOpen, handleClose, processImg, openCamModal, handleFileUploadClick } = props;

  return (
    <Styleddialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <Box style={{display: "flex", flexDirection: "column"}}>
          <Typography
            style={{
              color: "#64748B",
              fontSize: "16px",
              fontWeight: 600,
              margin: "auto"
            }}
          >
            EDIT PROFILE PICTURE
          </Typography>
          <Divider style={{ margin: "10px 0px", backgroundColor: "#CBD5E1" }} />
          <Typography
            style={{
              color: "black",
              fontWeight: 600,
              fontSize: "20px",
              cursor: "pointer",
              margin: "auto"
            }}
            onClick={() => {
              openCamModal(true);
              handleClose(false);
            }}
          >
            Take photo
          </Typography>
          <Divider style={{ margin: "10px 0px", backgroundColor: "#CBD5E1" }} />
          <Typography
            style={{
              color: "black",
              fontWeight: 600,
              fontSize: "20px",
              cursor: "pointer",
              margin: "auto"
            }}
            data-test-id="photo-library"
            onClick={() => {
              handleFileUploadClick();
              handleClose(false);
            }}
          >
            Photo library
          </Typography>
          <Divider style={{ margin: "10px 0px", backgroundColor: "#CBD5E1" }} />
          <Typography
            style={{
              color: "black",
              fontWeight: 600,
              fontSize: "20px",
              cursor: "pointer",
              margin: "auto"
            }}
            onClick={() => {
              processImg(null);
              handleClose(false);
            }}
          >
            Remove photo
          </Typography>
          <Divider style={{ margin: "10px 0px", backgroundColor: "#CBD5E1" }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant="contained"
          onClick={() => handleClose(false)}
          color="primary"
          btnText="Cancel"
          dataTestId="btn-submit"
          style={{
            margin: "10px 15px",
            height: "56px",
            fontSize: "16px",
            background:"linear-gradient(90deg, #C49A98 0%, #DCC2C0 100%)",
            color: "#000000"
          }}
        />
      </DialogActions>
    </Styleddialog>
  );
};

export default CustomEditImageModal;
